$breakpointMobile: 1465px;
$breakpointTrueMobile: 700px;
$breakpoint4k: 3000px;
// ! colors
$dark: #0D1116;
$light: #161C23;
$white: #ffffff;
$lightGray: #c0c0c0;
$darkGray: #2E2E2E;
$black: #242D37;
$brand: #9327FF;
$danger: tomato;
// ! width
$widthSmall: 320px;
// ! border
$borderRadiusMax: 30px;
$borderRadiusBig: 16px;
$borderRadiusSmall: 6px;