@import "../../vars.scss";

// ! < $breakpointMobile
@media (max-width:$breakpointMobile) {	
	.cards{
		// padding: 30px 0;
		.title{
			margin-top: -15px;
		}
	}
	.postFull{
		width: 100%;
		// radar in ComapanyFull
		.chart{
			width: 100%;
		}
	}
}


// ! > 550
@media (min-width:550px) {
	.postFull{
		// radar in ComapanyFull
		.chart{
			width: 40% !important;
			min-width: 450px;
		}
		.pie{
			width: 40% !important;
		}
	}
}

// ! > $breakpoint4k
@media (min-width:$breakpoint4k) {
	.postFull{
		// radar in ComapanyFull
		.chart{
			width: 30% !important;
		}
		.pie{
			width: 20% !important;
		}
	}
}

// !! center div with flex-start inside
@media (max-width:1799px) {	
	.cards:not(.table){
		// width: 1050px;	
	}
}
@media (max-width:1049px) {	
	.cards:not(.table){
		width: 660px;	
	}
}
@media (max-width:714px) {	
	.cards:not(.table){
		width: 320px;	
	}
	.table{
		width: 100%;
	}
}