@import "../../vars.scss";

.hello{
	position: relative;
	height: 173px;
	padding-top: 15px;
	// !!
	* {
		margin-bottom: 15px;
	}
	&__title{
		font-weight: 500;
		font-size: 28px;
		text-align: center; 
	}
	&__btn{
		background: $dark;
		width: 15%;
	}
	&__btnRight{
		position: absolute;
		right: 15px;
		bottom: 0px;
	}
}