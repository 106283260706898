@import "../../vars.scss";

.rightBar{
	border-radius: $borderRadiusBig;
	// !!
	width: 360px;
	// center bonusOne
	.cards{
		justify-content: center;
	}
	// center all titles
	.title,.title2{
		text-align: center;
	}
	.cards{
		// !! don't touch. fix for articles go crazy in rightBar after comment
		flex-direction: column;
		width: 360px;
		padding: 0 20px;
	}
}

.bigFlex{
	display: flex;
	gap: 15px;
}

.addPost{
	// !!
	textarea{
		height: 200px;
	}
}

.description{
	padding: 30px;
	padding-bottom: 15px;
	background: $dark;
	// !!
	> * {
		margin-bottom: 15px;
	}
}

.overview{
	margin-bottom: 15px;
	padding-bottom: 15px;
	// !!
	> * {
		margin-bottom: 15px;
	}
	img{
		border-radius: $borderRadiusBig;
		width: 100%;
	}
}

// !! fix all <pre> tags text
pre{
	white-space: pre-line;
	hyphens: auto;
	// !! force margin inside <pre> tags
	p,h1,h2,h3,h4,h5,h6 {
		margin-bottom: 15px;
	}
	}

// ! RadarSaveImg
.addPost .card{
	display: none; // hidden for < 1920px screens
	position: fixed;
	z-index: 2;
	right: 40px;
	top: 135px;
	pointer-events: none;
	.chart{
		height: 338px;
		width: 338px;
	}
	.iconsTop,.iconsMid{
		display: none; // hide RadarSaveImg card icons
	}
}

@media (min-width:(1919px)) {
	.addPost .card{
		display: block; // shown for >= 1920px screens
	}
}
// ? RadarSaveImg