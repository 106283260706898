@import "../../vars.scss";

// !!
header{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 90px;
	width: 100%;
	padding: 20px;
	border-radius: $borderRadiusBig;
	// !!
	a{
		width: fit-content;
		height: fit-content;
	}
}

// !!
nav{
	display: flex;
	flex-direction: column;
	width: $widthSmall;
	padding: 15px;
	font-size: 22px;
	border-radius: $borderRadiusBig;
	// !!!
	margin-right: 15px;
	// !!
	// * distance between IconText(s) in NavBottom/Nav...
	// * nav icons
	a{
		margin-bottom: 15px;
		width: $widthSmall;
		&:hover{
			color: $brand;
		}
	}
}

// !!
footer{
	width: 100%;
	// !!
	> * {
		// min-width: 320px;
		// max-width: 620px;
		flex: 1 1;
	}
}

// ! menu
.menu{
	position: fixed;
	top: 0; 
	left: 0; 
	z-index: 8;
	width: 100%;
	height: 100vh;
	padding-top: 80px;
	background: $light;
	&__scroll{
		height: 85vh;
		overflow: auto;
		overflow-x: hidden;
	}
	// !!
	nav{
		width: 100%;
		// height: 100%;
	}
}
// ? menu

// ! logo(s)
.logo{
	&_user{
		position: relative;
		z-index: 2;
	}
}
// ? logo(s)


// ! Burger
#burger__toggle {
	opacity: 0;
	width: 0;
 }
 #burger__toggle:checked + .burger__btn > span {
	transform: rotate(45deg);
 }
 #burger__toggle:checked + .burger__btn > span::before {
	top: 0;
	transform: rotate(0deg);
 }
 #burger__toggle:checked + .burger__btn > span::after {
	top: 0;
	transform: rotate(90deg);
 }
 #burger__toggle:checked ~ .burger__box {
	left: 0 !important;
 }
 .burger__btn {
	 position: fixed;
	 top: 25px;
	 right: 20px;
	 padding:20px 0;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 9;
 }
 .burger__btn > span,
 .burger__btn > span::before,
 .burger__btn > span::after {
	display: block;
	position: absolute;
	width: 100%;
	height: 2px;
	background: $white;
	transition-duration: 0.25s;
 }
 .burger__btn > span::before {
	content: "";
	top: -8px;
 }
 .burger__btn > span::after {
	content: "";
	top: 8px;
 }
 
// ? Burger