.chart{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.radarClip{
	position: absolute;
	&0{
		transform: rotate(calc(0deg + 22deg));
	}
	&1{
		transform: rotate(calc(45deg + 22deg));
	}
	&2{
		transform: rotate(calc(90deg + 22deg));
	}
	&3{
		transform: rotate(calc(135deg + 22deg));
	}
	&4{
		transform: rotate(calc(180deg + 22deg));
	}
	&5{
		transform: rotate(calc(225deg + 22deg));
	}
	&6{
		transform: rotate(calc(270deg + 22deg));
	}
	&7{
		transform: rotate(calc(315deg + 22deg));
	}
}