@import "../../vars.scss";

// ! < $breakpointMobile
@media (max-width:$breakpointMobile) {
	.comments{
		width: 100%;
	}
}


// ! > $breakpointMobile
@media (min-width:($breakpointMobile+1)) {

}