@import "../../vars.scss";

// ! < $breakpointMobile
@media (max-width:$breakpointMobile) {	
	.banner{
		display: none;
	}
}


// ! > $breakpointMobile
@media (min-width:($breakpointMobile+1)) {

}