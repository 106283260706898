@import "../../vars.scss";

.banner{
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: -15px;
	&__logo{
		position: absolute;
		width: 50%;
		height: 55%;
		align-self: center;
		display: flex;
		justify-content: center;
		gap: 20px;
	}
	.banner__img{
		border-radius: $borderRadiusBig;
		width: 100%;
		height: 175px;
	}
}