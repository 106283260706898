@import "../../vars.scss";

// ! < $breakpointMobile
@media (max-width:$breakpointMobile) {	
	.pie{
		width: 80%;
	}	
	.doughnut{

	}
}


// ! > $breakpointMobile
@media (min-width:($breakpointMobile+1)) {
	.pie{
		width: 30%;
	}
	.doughnut{
		width: 15%;
	}
}

// ! radarClip size
.radarClip{
	height: calc(100% - 56px) !important;
}
// ! < 389px
@media (max-width:389px) {	
	.radarClip{
		height: calc(100% - 70px) !important;
		margin-left: 5px;
	}
}
// ? radarClip size