@import "../../vars.scss";

.comment{
	&__title{
		display: flex; 
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%; 
		margin-left: 15px; 
		margin-top: 15px;
		font-weight: 700;
		word-break: break-all;
	}
	&__text{
		padding-left: 70px;
		font-weight: 200;
		display: flex;
		word-break: break-all;
		// !!
		img{
			border-radius: $borderRadiusBig;
			width: 100%;
			height: 300px;
			object-fit: contain;
		}
	}
	&__userName{
		// so flex works ok
		min-width: 250px;
	}
}

.comments{
	&__scroll{
		max-height: 1000px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	// !!
	> * {
		margin-bottom: 24px;
	}
}

.scrollTo{
	position: fixed;
	bottom: 55px;
	right: 10px;
	z-index: 3;
	background: $dark;
	border-radius: 50%;
	padding: 10px;
	width: 50px;
	// !!
	&.toheader{
		bottom: 0px;
	}
}