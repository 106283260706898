.iconText{
	display: flex;
	align-items: center;
}

.Icon2Text{
	position: relative;
	.text1,.text2{
		position: relative;
		top: -15px;
	}
	.text1{
		font-weight: 700;
	}
	.text2{
		position: relative;
		top: -40px;
		left: 105px;
		font-weight: 200; 
		// !! n min read fix for small phones
		@media (max-width:359px) {	
			width: 110px;
		}
	}
}

.blur{
	animation: blur .5s forwards;
}

@keyframes blur {
	to{
		filter: blur(1px);
	}
}