@import "../../vars.scss";

.profileMenu{
	position: absolute;
	top: 100px;
	right: 0px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 20px 15px;
	min-width: 290px;
	border-radius: $borderRadiusBig;
	z-index: 2;
	// profileMenu hover
	a {
		&:hover{
			color: $brand;
		}
	}
}

.userChangeName{
	display: flex;
	flex-direction: column;
	gap: 15px;
	// !!
	input, button{
		height: 37px;
		width: 260px;
	}
}