@import "../../vars.scss";

.errorMsg{
	color: $danger; 
	border: 1px solid $danger;
	width: fit-content;
	margin: 0 auto;
	padding: 10px;
	margin-top: 15px;
	border-radius: $borderRadiusSmall;
}

.inputButton{
	position: relative;
	// !!
	button{
		position: absolute;
		right: 5px;
		top: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 80% !important;
		width: 130px !important;
	}
}

// !!
[type="file"]{
	display: none;
}

.addImage{
	height: 50px;
	margin-right: 15px;
}

.tags{
	display: flex; 
	flex-wrap: wrap;
	gap: 15px;
}

.showMore{
	position: relative;
	left: 50%;
	transform: translate(-50%);
	margin-bottom: 15px;
}